import * as React from "react";
// import Slider from "react-slick";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
// import Title from "../components/title"
import Inview from "../components/inview";
const Roundsring = ({ data }) => {
  const pageData = {
    titleClass: "roundsling",
    title: "ROUND SRING",
    discription: "どんな荷姿にも自在にフィットし、安定性も抜群です。内側は強力ポリエステル繊維を束ね、表面布で覆った形状の柔らかいスリング です。軽くてソフトな材質で吊りにくい荷姿にも柔軟になじみ、滑りも少なく吊り荷をキズつけることなく安心してご使用頂けます。",
    image: 12,
  };
  const seodata = {
    title: "ソフトな吊り具 ラウンドスリング [ROUND SRING] アヴィエラン株式会社",
    seo: {
      description: "どんな荷姿にも自在にフィットし、安定性も抜群です。内側は強力ポリエステル繊維を束ね、表面布で覆った形状の柔らかいスリング です。軽くてソフトな材質で吊りにくい荷姿にも柔軟になじみ、滑りも少なく吊り荷をキズつけることなく安心してご使用頂けます。",
      image: {
        url: data.datoCmsImage.topimage[12].url,
      },
    },
    slug: "roundsling",
  };
  //   const newsData = data.allDatoCmsNews.nodes
  const imageData = data.datoCmsImage;
  return (
    <Layout pagestyle={pageData} seo={seodata}>
      <div className="pagetitle lashingbelt">
        <div className="fixedtitleimg productimg roundimg">
          <Img fluid={imageData.productsImg[7].item} alt="ソフトな吊り具 ラウンドスリング" />
        </div>
        <div className="fixedtexttitle">
          <h1>ROUND SRING</h1>
        </div>
      </div>
      <div className="pankz">
        <Link to="/">トップ</Link>
        <Link to="/logisticsequipment/">物流機器事業本部</Link>
        <p>ROUND SRING</p>
      </div>
      <div className="l_page_contents">
        <div className="box1">
          <Inview c_name="image">
            <Img fluid={data.datoCmsImage.productsImg[23].item} alt="ソフトな吊り具、ラウンドスリング" />
            <Inview c_name="title" tag="h2">
              ソフトな吊り具、ラウンドスリング
              <span>A soft hanging tool</span>
            </Inview>
          </Inview>
          <div className="text">
            <Inview tag="p" c_name="comment">
              どんな荷姿にも自在にフィットし、安定性も抜群です。 内側は強力ポリエステル繊維を束ね、表面布で覆った形状の柔らかいスリング です。軽くてソフトな材質で吊りにくい荷姿にも柔軟になじみ、滑りも少なく吊り荷をキズつけることなく安心してご使用頂けます。
            </Inview>
          </div>
        </div>
        <div className="itemdiscription round">
          <div>
            <h3>標準規格品</h3>
            <p>CEマーク取得商品・JIS規格準拠(JIS B 8811)</p>
          </div>
          <div>
            <h3>庭石・植木・などの運搬に最適なベルト</h3>
            <p>強力ポリエステルのより糸を束ね、筒状の厚手の表面布（ポリエステル）で覆った構造のスリングです。軽くてソフトな材質ですので、吊りにくい荷姿の物にも柔軟になじみ、吊り荷をキズつけることなく安心してご使用いただけます。</p>
          </div>
          <div>
            <h3>補償も万全　生産物賠償責任保険［PL保険］加入商品</h3>
            <p>製品の欠陥によって、その製品の消費者その他第三者が生命・身体または財産に被害を被った場合、その製品の製造・販売に関与した事業者が、被害者に対して負うべき法律上の損害賠償責任をＰＬ（製造物責任）といいます。</p>
          </div>
        </div>

        <div className="lineup round">
          <div>
            <p className="title">
              LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5532" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[24].item} alt="1t用" />
              <span>1t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5533" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[25].item} alt="2t用" />
              <span>2t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5534" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[26].item} alt="3t用" />
              <span>3t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5535" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[27].item} alt="4t用" />
              <span>4t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5536" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[7].item} alt="5t用" />
              <span>5t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5537" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[28].item} alt="6t用" />
              <span>6t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5538" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[8].item} alt="8t用" />
              <span>8t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5539" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[29].item} alt="10t用" />
              <span>10t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5540" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[30].item} alt="15t用" />
              <span>15t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5541" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[31].item} alt="20t用" />
              <span>20t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5542" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[84].item} alt="軽量8t用" />
              <span>軽量8t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5543" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[84].item} alt="軽量10t用" />
              <span>軽量10t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5544" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[84].item} alt="軽量15t用" />
              <span>軽量15t用</span>
            </a>
          </div>
          <div>
            <a href="https://www.slingbelt.com/products/detail/5545" target="_blank" rel="noreferrer">
              <Img fluid={data.datoCmsImage.productsImg[84].item} alt="軽量20t用" />
              <span>軽量20t用</span>
            </a>
          </div>
          <div>
            <p className="title">
              LINE UP
              <br />
              [ONLINE STORE]
            </p>
          </div>
        </div>

        <div className="itemspec">
          <div>
            <h3>基本使用荷重</h3>
            <div className="spectable">
              <table className="slingspec1">
                <tbody>
                  <tr>
                    <th>　</th>
                    <th>ストレート吊り</th>
                    <th>チョーク吊り</th>
                    <th colSpan="2">2点吊り</th>
                    <th colSpan="2">4点吊り</th>
                  </tr>
                  <tr>
                    <td className="center">吊り方</td>
                    <td className="center">
                      <div className="imgcenter">
                        <Img fluid={data.datoCmsImage.productsImg[12].tebleicon} alt="ストレート吊" />
                      </div>
                    </td>

                    <td className="center">
                      <div className="imgcenter">
                        <Img fluid={data.datoCmsImage.productsImg[13].tebleicon} alt="チョーク吊り" />
                      </div>
                    </td>
                    <td colSpan="2" className="center">
                      <div className="imgcenter">
                        <Img fluid={data.datoCmsImage.productsImg[14].tebleicon} alt="2点吊り" />
                      </div>
                    </td>
                    <td colSpan="2" className="center">
                      <div className="imgcenter">
                        <Img fluid={data.datoCmsImage.productsImg[15].tebleicon} alt="4点吊り" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="center">吊り角度</td>
                    <td> </td>
                    <td> </td>
                    <td className="center">α≦45°</td>
                    <td className="center">45°&lt;α≦90°</td>
                    <td className="center">α≦45°</td>
                    <td className="center">45°&lt;α≦90°</td>
                  </tr>
                  <tr className="center">
                    <td>係数</td>
                    <td>1(基本)</td>
                    <td>0.8</td>
                    <td>1.8</td>
                    <td>1.4</td>
                    <td>3.6</td>
                    <td>2.8</td>
                  </tr>
                  <tr>
                    <td>1t用</td>
                    <td>1.0t</td>
                    <td>0.8t</td>
                    <td>1.8t</td>
                    <td>1.4t</td>
                    <td>3.6t</td>
                    <td>2.8t</td>
                  </tr>
                  <tr>
                    <td>2t用</td>
                    <td>2.0t</td>
                    <td>1.6t</td>
                    <td>3.6t</td>
                    <td>2.8t</td>
                    <td>7.2t</td>
                    <td>5.6t</td>
                  </tr>
                  <tr>
                    <td>3t用</td>
                    <td>3.0t</td>
                    <td>2.4t</td>
                    <td>5.4t</td>
                    <td>4.2t</td>
                    <td>10.8t</td>
                    <td>8.4t</td>
                  </tr>
                  <tr>
                    <td>4t用</td>
                    <td>4.0t</td>
                    <td>3.2t</td>
                    <td>7.2t</td>
                    <td>5.6t</td>
                    <td>14.4t</td>
                    <td>11.2t</td>
                  </tr>
                  <tr>
                    <td>5t用</td>
                    <td>5.0t</td>
                    <td>4.0t</td>
                    <td>9.0t</td>
                    <td>7.0t</td>
                    <td>18.0t</td>
                    <td>14.0t</td>
                  </tr>
                  <tr>
                    <td>6t用</td>
                    <td>6.0t</td>
                    <td>4.8t</td>
                    <td>10.8t</td>
                    <td>8.4t</td>
                    <td>21.6t</td>
                    <td>16.8t</td>
                  </tr>
                  <tr>
                    <td>8t用</td>
                    <td>8.0t</td>
                    <td>6.4t</td>
                    <td>14.4t</td>
                    <td>11.2t</td>
                    <td>28.8t</td>
                    <td>22.4t</td>
                  </tr>
                  <tr>
                    <td>10t用</td>
                    <td>10.0t</td>
                    <td>8.0t</td>
                    <td>18.0t</td>
                    <td>14.0t</td>
                    <td>36.0t</td>
                    <td>28.0t</td>
                  </tr>
                  <tr>
                    <td>15t用</td>
                    <td>15.0t</td>
                    <td>12.0t</td>
                    <td>27.0t</td>
                    <td>21.0t</td>
                    <td>54.0t</td>
                    <td>42.0t</td>
                  </tr>
                  <tr>
                    <td>20t用</td>
                    <td>20.0t</td>
                    <td>16.0t</td>
                    <td>36.0t</td>
                    <td>28.0t</td>
                    <td>72.0t</td>
                    <td>56.0t</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </Layout>
  );
};

export default Roundsring;

export const query = graphql`
  query {
    allDatoCmsNews {
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        item: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "600", h: "600" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      productsImg {
        tebleicon: fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop", w: "60", h: "60" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
